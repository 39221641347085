import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

export const MapContainer = (props) => {
  const onMarkerClick = (e) => {
    console.log(e);
  };
  return (
    <Map
      google={props.google}
      zoom={14}
      initialCenter={{
        lat: 12.946119,
        lng: 80.197076,
      }}
    >
      <Marker
        position={{ lat: 12.946119, lng: 80.197076 }}
        onClick={onMarkerClick}
        name={"Indian location"}
      />
      <Marker
        position={{ lat: 37.2915698, lng: -121.7624729 }}
        onClick={onMarkerClick}
        name={"US location"}
      />
      <Marker
        position={{ lat: 51.526079, lng: -0.1288882 }}
        onClick={onMarkerClick}
        name={"UK location"}
      />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBmGmeot5jcjdaJTvfCmQPfzeoG_pABeWo",
})(MapContainer);
